@font-face {
    font-family: 'Albertus Nova';
    font-weight: '900';
    src: url('/_dev/_source/fonts/36FE3B_3_0.eot');
    src: url('/_dev/_source/fonts/36FE3B_3_0.eot?#iefix') format('embedded-opentype'),url('/_dev/_source/fonts/36FE3B_3_0.woff2') format('woff2'),url('/_dev/_source/fonts/36FE3B_3_0.woff') format('woff'),url('/_dev/_source/fonts/36FE3B_3_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Albertus Nova';
    font-weight: '700';
    src: url('/_dev/_source/fonts/36FE3B_4_0.eot');
    src: url('/_dev/_source/fonts/36FE3B_4_0.eot?#iefix') format('embedded-opentype'),url('/_dev/_source/fonts/36FE3B_4_0.woff2') format('woff2'),url('/_dev/_source/fonts/36FE3B_4_0.woff') format('woff'),url('/_dev/_source/fonts/36FE3B_4_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Albertus Nova';
    font-weight: '300';
    src: url('/_dev/_source/fonts/36FE3B_5_0.eot');
    src: url('/_dev/_source/fonts/36FE3B_5_0.eot?#iefix') format('embedded-opentype'),url('/_dev/_source/fonts/36FE3B_5_0.woff2') format('woff2'),url('/_dev/_source/fonts/36FE3B_5_0.woff') format('woff'),url('/_dev/_source/fonts/36FE3B_5_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Albertus Nova';
    font-weight: '400';
    src: url('/_dev/_source/fonts/36FE3B_6_0.eot');
    src: url('/_dev/_source/fonts/36FE3B_6_0.eot?#iefix') format('embedded-opentype'),url('/_dev/_source/fonts/36FE3B_6_0.woff2') format('woff2'),url('/_dev/_source/fonts/36FE3B_6_0.woff') format('woff'),url('/_dev/_source/fonts/36FE3B_6_0.ttf') format('truetype');
}