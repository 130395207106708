.house-title {
    $this: '.house-title';
    
    p{
        font-weight: 600;
        text-transform: uppercase;
        line-height: 0.375rem;
    
        @media (max-width: 768px) {
            line-height: .325rem;
            font-size: .275rem;
        }
    }    
}