.underline-under {
    text-underline-position: under
}

.padded-container {
    @apply px-[var(--container-gutter-width)];
    --container-gutter-margin: 1rem;
    --container-gutter-width: 1rem;

    @screen md {
        --container-gutter-margin: 4rem;
        --container-gutter-width: 4rem;
    }
    @media screen and (min-width: #{$containerWidth}) {
        $padding-calc: '((100vw - #{$containerWidth}) / 2) + var(--container-gutter-margin)';

        --container-gutter-width: calc((#{$padding-calc}) + 1px);
    }
}