﻿//*------------------------------------
//  Generic scroll behaviour
//  ------------------------------------*/

html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

@include reduced-motion {
    html {
        scroll-behavior: auto;
    }
}
