// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]

////
/// @group -Project-
////

$form__error-class: '.error';
$form__error-message-selector: '.error-message';

#{$form__error-message-selector} {
    @apply block text-sm font-medium text-red-600 mt-2;
}