html {
  overflow-x: hidden;
  // margin-right: calc(-1 * (100vw - 100%));
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

svg {
  pointer-events: none;
  
  use {
    fill: currentColor;
    &[href*="-outline-"] {
      stroke: currentColor;
      stroke-width: 1.5;
    }
  }
}

img {
  min-height: 1px;
  min-width: 1px;
  width: fit-content;
}

// Remove iOS input shadows
input,
textarea {
  background-clip: padding-box !important
}

address {
  font-style: normal;
}