$is-backend: false;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import "tailwindcss/utilities";

@import '@splidejs/splide/dist/css/splide-core.min.css';

@import '_sprites.scss';
@import '../../../../node_modules/@yuzu/yuzu-plugin-base/_dev/_source/js/animation/animate';
// =======================================
// 
//              Library
// 
// ======================================
@import 'library/_settings*.scss';
@import 'library/_tools*.scss';
@import 'library/_generic*.scss';
@import 'library/_utility*.scss';
@import 'library/_object*.scss';

// =======================================
// 
//           Project Config
// 
// ======================================
@import 'projectConfig/_settings*.scss';
@import 'projectConfig/_tools*.scss';
@import 'projectConfig/_generic*.scss';
@import 'projectConfig/_utility*.scss';
@import 'projectConfig/_object*.scss';

// =======================================
// 
//           Blocks/Pages
// 
// ======================================
@import '../../../_templates/**/*.scss';

// =======================================
// 
//           Base Blocks/Pages
// 
// ======================================
@import '../../../../node_modules/@yuzu/yuzu-plugin-base/_dev/_templates/**/*.scss';

[v-cloak] {
    display: none;
}

.button-link-list a,.button-link-list button{
    clip-path: polygon(0 20px, 0 100%, 100% 100%, 100% 0, 20px 0);
}

.button-link-list {
    a,button{
        clip-path: polygon(0 20px, 0 100%, 100% 100%, 100% 0, 20px 0);
    }
}